import tools from '../../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: "",
    slug: "",
    translations: {}
};
const translation = {
    tag_id: "",
    locale: "",
    name: ""
};

export default {
    ...tools.models.apiCommons(model, translation, "tag_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            }
        }
    },
    translationValidation: {
        model: {
            name: { required },
            publish_state: { required }
        }
    }
};
